const normalizeDate = (
  date: Date | number | { nanoseconds: number; seconds: number }
) => {
  if (typeof date === "number" || date instanceof Date) {
    return new Date(date);
  }

  return date?.seconds ? new Date(date.seconds * 1000) : new Date();
};

export { normalizeDate };
