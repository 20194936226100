export const audioFileToDataURI = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const audioUrlToFile = (url: string) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        resolve(new File([blob], "audio.mp3", { type: blob.type }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};
