export const videoFileToDataURI = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const videoUrlToFile = (url: string) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        resolve(new File([blob], "video.mp4", { type: blob.type }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};
