import { SUPPORTED_AUDIO_MIME_TYPES } from "constants/validationRules";

interface AudioPlayerProps {
  audioSrc?: string;
  articleContent?: string;
  articleTitle?: string;
}

function AudioPlayer({ audioSrc }: AudioPlayerProps) {
  const renderSources = () => {
    if (!audioSrc) return null;
    return (
      <>
        {SUPPORTED_AUDIO_MIME_TYPES.map((type) => (
          <source src={audioSrc} type={type} key={type} />
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col overflow-hidden">
      <audio controls className="px-2 sm:max-w-none" controlsList="nodownload">
        {renderSources()}
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default AudioPlayer;
