import { SUPPORTED_VIDEO_MIME_TYPES } from "constants/validationRules";

interface VideoPlayerProps {
  videoSrc?: string;
  fullSize?: boolean;
}

function VideoPlayer({ videoSrc, fullSize }: VideoPlayerProps) {
  const renderSources = () => {
    if (!videoSrc) return null;
    return (
      <>
        {SUPPORTED_VIDEO_MIME_TYPES.map((type) => (
          <source src={videoSrc} type={type} key={type} />
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col overflow-hidden">
      <video
        controls
        className="max-h-96 sm:max-w-2xl"
        controlsList="nodownload"
      >
        {renderSources()}
        Your browser does not support the video element.
      </video>
    </div>
  );
}

export default VideoPlayer;
