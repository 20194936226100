import { useState, FormEvent, SyntheticEvent } from "react";
import Link from "next/link";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { withBreaksAndLinks } from "utils/textUtils";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import { useAuth } from "context/AuthContext";
import { db } from "utils/firebase";
import { Avatar } from "components/common";
import { getInitials } from "utils/textUtils";
import { addComment } from "pages/api/comment";
import { getProfileLink } from "utils/linkUtils";

interface CommentProps {
  postId: string;
  commentId: string;
  authorId: string;
  authorProfileImageUrl?: string;
  createdAt: Date;
  content?: string;

  isChild?: boolean;
}

function Comment({
  postId,
  commentId,
  authorId,
  createdAt,
  content,
  isChild = false,
}: CommentProps) {
  const { user } = useAuth();
  const [showReplyTextArea, setShowReplyTextArea] = useState(false);
  const [replyContent, setReplyContent] = useState("");

  const [userProfile] = useDocument(doc(db, `users`, user?.uid || "0"));
  const [commentAuthor] = useDocument(doc(db, "publicUsers", authorId || "0"));

  const userProfileData = userProfile?.data();
  const commentAuthorData = commentAuthor?.data();

  const handleReplyContentChange = (e: FormEvent<HTMLTextAreaElement>) =>
    setReplyContent(e.currentTarget.value);

  const handleReplyButtonClick = () => setShowReplyTextArea(!showReplyTextArea);
  const getReplyButtonText = () => (showReplyTextArea ? "Cancel" : "Reply");

  const handleAddCommentSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowReplyTextArea(false);

    await addComment({
      details: {
        postId,
        authorId: user?.uid || "",
        content: replyContent,
        parentId: commentId,
      },
    });

    setReplyContent("");
  };

  return (
    <div className="relative flex h-min w-full pt-6" key={commentId}>
      {isChild ? (
        <div className="relative h-full w-12 flex-shrink-0 ">
          <div className="h-full border-r border-gray-300"></div>
        </div>
      ) : null}

      <div className="flex w-full flex-col">
        <div className="relative mb-4 flex items-start justify-center  space-x-3">
          <div className="relative flex flex-shrink-0 flex-col items-start justify-start">
            <Link
              href={getProfileLink(commentAuthorData)}
              className="rounded-full border border-transparent hover:border-orange-500"
            >
              <Avatar
                initials={getInitials(commentAuthorData?.name)}
                imageUrl={commentAuthorData?.profileImageUrl}
                size="m"
              />
            </Link>
          </div>

          <div className="min-w-0 flex-1">
            <Link
              className="w-full cursor-pointer text-sm font-medium text-gray-900 hover:underline"
              href={getProfileLink(commentAuthorData)}
            >
              {commentAuthorData?.name || "Anonymous"}
            </Link>

            <span className="ml-4 text-sm text-gray-600">
              {dayjs(createdAt).fromNow()}
            </span>

            <div className="mt-1 text-gray-500">
              {withBreaksAndLinks(content)}
            </div>

            {handleReplyButtonClick && !isChild && user ? (
              <button
                onClick={handleReplyButtonClick}
                type="button"
                className="mt-2 inline-flex items-center rounded-md border border-transparent py-2 text-sm font-medium text-gray-500 hover:text-gray-600 focus:text-gray-700 focus:outline-none"
              >
                <ChatBubbleOvalLeftEllipsisIcon
                  className="mr-2 inline-block h-4 w-4 items-center"
                  aria-hidden="true"
                />
                {getReplyButtonText()}
              </button>
            ) : null}

            {showReplyTextArea ? (
              <div className="mt-2 flex items-center">
                <div className="flex flex-shrink-0 justify-center">
                  <Avatar
                    initials={getInitials(userProfileData?.name)}
                    imageUrl={userProfileData?.profileImageUrl}
                    size="m"
                  />
                </div>
                <div className="ml-3 min-w-0 flex-1">
                  <form
                    onSubmit={handleAddCommentSubmit}
                    className="group flex w-full"
                  >
                    <textarea
                      rows={2}
                      name="replyComment"
                      id="replyComment"
                      autoComplete="off"
                      className="block w-full resize-none  rounded-l-md border border-gray-300 px-4 py-2 focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Add a reply..."
                      value={replyContent}
                      onChange={handleReplyContentChange}
                    />
                    <button
                      disabled={!replyContent}
                      type="submit"
                      className="inline-flex items-center rounded-r-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-orange-600 disabled:opacity-70"
                    >
                      Post
                    </button>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Comment;
