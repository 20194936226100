import Image from "next/image";

import { useWindowSize } from "react-use";
interface ImageViewProps {
  imageSrc?: string;
}

function ImageView({ imageSrc }: ImageViewProps) {
  const { width, height } = useWindowSize();

  return (
    <div className="f-ull relative  w-full overflow-hidden">
      <Image
        alt="Uploaded image"
        src={imageSrc}
        width={width}
        height={height}
        className="object-contain group-hover:opacity-70"
      />
    </div>
  );
}

export default ImageView;
