import {
  useState,
  FormEvent,
  useEffect,
  SyntheticEvent,
  useCallback,
  createContext,
  useRef,
} from "react";
import Image from "next/image";
import { XMarkIcon, ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { CameraIcon, MusicalNoteIcon } from "@heroicons/react/24/solid";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

import {
  SUPPORTED_AUDIO_FORMATS,
  SUPPORTED_AUDIO_MIME_TYPES,
  SUPPORTED_VIDEO_MIME_TYPES,
  SUPPORTED_IMAGE_MIME_TYPES,
  SUPPORTED_IMAGE_FORMATS,
  USER_VALIDATIONS,
  SUPPORTED_VIDEO_FORMATS,
  MAX_UPLOAD_MEGA_BYTES,
} from "constants/validationRules";
import { Avatar, Loader, PublishButton } from "components/common";
import { useLeavePageConfirm } from "utils/customHooks";
import AudioPlayer from "./AudioPlayer";
import VideoPlayer from "./VideoPlayer";
import ImageView from "./ImageView";
import { audioFileToDataURI, audioUrlToFile } from "utils/audioUtils";
import { videoFileToDataURI, videoUrlToFile } from "utils/videoUtils";
import { imageFileToDataUrl, objectUrlToFile } from "utils/imageUtils";
import { Post } from "models/Post";
import { useAuth } from "context/AuthContext";
import ExtensionIconGroup from "components/Extensions/ExtensionIconGroup";

export const ExtensionStandardModalContext = createContext({
  topic: "",
  setContent: (content: string) => {},
  handleEditPending: (disable: boolean) => {},
});

interface StandardModalProps {
  textAreaRef?: any;
  onCloseClick: () => void;
  onOpenEditorClick: () => void;
  onTextContentChange: (textContent: string) => void;
  onPublishClick?: (
    textContent: string,
    audioContent: File,
    videoContent: File,
    imageContent: File
  ) => Promise<void>;
  userInitials?: string;
  userProfileImage?: string;
  defaultContent?: string;
  isLoading?: boolean;
  editPostData?: Post;
}

function StandardModal({
  textAreaRef,
  onCloseClick,
  onOpenEditorClick,
  onTextContentChange,
  onPublishClick,
  userInitials,
  userProfileImage,
  defaultContent,
  isLoading = false,
  editPostData,
}: StandardModalProps) {
  const { user } = useAuth();

  const [content, setContent] = useState("");
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [audioContent, setAudioContent] = useState<File>();
  const [audioDataURI, setAudioDataURI] = useState<any>("");
  const [videoContent, setVideoContent] = useState<File>();
  const [videoDataURI, setVideoDataURI] = useState<any>("");
  const [imageContent, setImageContent] = useState<File>();
  const [imageDataURL, setImageDataURL] = useState<any>("");
  const [characterLength, setCharacterLength] = useState(0);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [editsPending, setEditsPending] = useState(false);

  const editsPendingRef = useRef(editsPending);

  const isEditMode = useCallback(
    () => !!editPostData?.authorId,
    [editPostData?.authorId]
  );

  useEffect(() => {
    editsPendingRef.current = editsPending;
  }, [editsPending]);

  useEffect(() => {
    if (editPostData?.shortTextContent)
      setContent(editPostData?.shortTextContent);

    if (editPostData?.imageSrc) {
      setImageDataURL(editPostData?.imageSrc);
      objectUrlToFile(editPostData?.imageSrc).then((file) => {
        if (file) setImageContent(file as File);
      });
    }

    if (editPostData?.audioSrc) {
      setAudioDataURI(editPostData?.audioSrc);
      audioUrlToFile(editPostData?.audioSrc).then((file) => {
        if (file) setAudioContent(file as File);
      });
    }

    if (editPostData?.videoSrc) {
      setVideoDataURI(editPostData?.videoSrc);
      videoUrlToFile(editPostData?.videoSrc).then((file) => {
        if (file) setVideoContent(file as File);
      });
    }
  }, [
    editPostData?.audioSrc,
    editPostData?.imageSrc,
    editPostData?.shortTextContent,
    editPostData?.videoSrc,
  ]);

  const handleContentChange = (e: FormEvent<HTMLTextAreaElement>) => {
    setCharacterLength(
      Math.min(
        e.currentTarget.value.length,
        USER_VALIDATIONS.shortTextPostMaxLength
      )
    );

    const newText = e.currentTarget.value.substring(
      0,
      USER_VALIDATIONS.shortTextPostMaxLength
    );

    setContent(newText);
    onTextContentChange && onTextContentChange(newText);
  };

  const remainingCharactersLeft = () =>
    USER_VALIDATIONS.shortTextPostMaxLength - characterLength;

  const getEditorControlContainerStyles = () =>
    remainingCharactersLeft() <= 30
      ? "border-gray-200 justify-between"
      : "border-transparent justify-end";

  const getCharactersLeftStyle = () => {
    if (remainingCharactersLeft() === 0) return "bg-red-600 text-white";
    else if (remainingCharactersLeft() <= 5) return "bg-red-500 text-white";
    else if (remainingCharactersLeft() <= 10) return "bg-red-400 text-white";
    else if (remainingCharactersLeft() <= 15) return "bg-red-300 text-gray-500";
    else if (remainingCharactersLeft() <= 20) return "bg-red-200 text-gray-500";
    else if (remainingCharactersLeft() <= 25) return "bg-red-100 text-gray-500";
    else return "bg-gray-100 text-gray-500";
  };

  const hasChanges = () =>
    (!editPostData?.authorId &&
      (!!content || !!imageContent || !!audioContent || !!videoContent)) ||
    (!!editPostData?.authorId &&
      (editPostData?.shortTextContent !== content ||
        editPostData?.imageSrc !== imageDataURL ||
        editPostData?.audioSrc !== audioDataURI ||
        editPostData?.videoSrc !== videoDataURI));

  const handleOnEditorClick = () => {
    setIsExpanding(true);
    onOpenEditorClick();
  };

  const handleOnPublishClick = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isExpanding) {
      setIsPublishing(true);
      onPublishClick &&
        onPublishClick(content, audioContent, videoContent, imageContent);
    }
  };

  useLeavePageConfirm(hasChanges() && !isExpanding && !isPublishing);

  const onFileDrop = async (acceptedFiles: File[], fileRejections) => {
    if (fileRejections.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error(
              `File too large. Max size is ${MAX_UPLOAD_MEGA_BYTES}MB.`
            );
          }

          if (err.code === "file-invalid-type") {
            toast.error(
              `Unsupported file type. Only ${SUPPORTED_IMAGE_FORMATS.join(
                ", "
              )} and ${SUPPORTED_IMAGE_FORMATS.join(", ")} are supported.}`
            );
          }
        });
      });
    }

    if (acceptedFiles && acceptedFiles.length) {
      if (SUPPORTED_AUDIO_MIME_TYPES.indexOf(acceptedFiles[0].type) > -1) {
        setIsUploadLoading(true);
        setAudioContent(acceptedFiles[0]);

        await audioFileToDataURI(acceptedFiles[0])
          .then((dataURI) => {
            setAudioDataURI(dataURI);
          })
          .then(() => setIsUploadLoading(false));
      } else if (
        SUPPORTED_VIDEO_MIME_TYPES.indexOf(acceptedFiles[0].type) > -1
      ) {
        setIsUploadLoading(true);
        setVideoContent(acceptedFiles[0]);

        await videoFileToDataURI(acceptedFiles[0])
          .then((dataURI) => {
            setVideoDataURI(dataURI);
          })
          .then(() => setIsUploadLoading(false));
      } else if (
        SUPPORTED_IMAGE_MIME_TYPES.indexOf(acceptedFiles[0].type) > -1
      ) {
        setIsUploadLoading(true);
        setImageContent(acceptedFiles[0]);

        await imageFileToDataUrl(acceptedFiles[0])
          .then((dataURI) => {
            setImageDataURL(dataURI);
          })
          .then(() => setIsUploadLoading(false))
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  const hasUploadedContent = () =>
    !!audioDataURI || !!videoDataURI || !!imageDataURL;

  const isAuthor = useCallback(
    () =>
      !editPostData?.authorId ||
      (!!editPostData?.authorId && user?.uid === editPostData?.authorId),
    [editPostData?.authorId, user?.uid]
  );

  const renderUploadedContent = () => {
    if (isUploadLoading) {
      return (
        <div className="flex min-h-[4rem] w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-1  hover:bg-gray-100  ">
          <Loader />
        </div>
      );
    }

    if (audioDataURI) {
      return (
        <div className="flex w-full flex-col items-center justify-center">
          <AudioPlayer audioSrc={audioDataURI} />
          <button
            className="mt-2 text-sm text-gray-400 underline hover:text-gray-500"
            onClick={handleDeleteAudioClick}
          >
            Delete audio
          </button>
        </div>
      );
    } else if (videoDataURI) {
      return (
        <div className="flex w-full flex-col items-center justify-center">
          <VideoPlayer videoSrc={videoDataURI} />
          <button
            className="mt-2 text-sm text-gray-400 underline hover:text-gray-500"
            onClick={handleDeleteVideoClick}
          >
            Delete video
          </button>
        </div>
      );
    } else if (imageDataURL) {
      return (
        <div className="group relative mt-2 flex w-full cursor-pointer flex-col items-center justify-center rounded-lg  text-gray-600 hover:bg-gray-400">
          <ImageView imageSrc={imageDataURL} />
          <button
            className=" absolute left-0 top-0 box-content h-full w-full rounded-lg border-0 border-transparent text-white opacity-0  transition-opacity hover:bg-gray-400 group-hover:opacity-90"
            onClick={handleRemoveImageContent}
          >
            Remove
          </button>
        </div>
      );
    }
  };

  const handleDeleteAudioClick = () => {
    setAudioContent(null);
    setAudioDataURI(null);
  };
  const handleDeleteVideoClick = () => {
    setVideoContent(null);
    setVideoDataURI(null);
  };
  const handleRemoveImageContent = () => {
    setImageContent(null);
    setImageDataURL(null);
  };

  const handleExtensionSetContent = (content: string) => {
    setContent((prev) => {
      const currentEditsPending = editsPendingRef.current;
      handleEditPending(false);
      return currentEditsPending ? content : prev;
    });
  };

  const handleEditPending = (disable: boolean) => setEditsPending(disable);

  const handleCancelExtension = () => setEditsPending(false);

  return (
    <>
      <div className=" flex flex-row justify-between">
        <div className="pl-4 pt-4 sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            onClick={onCloseClick}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="flex flex-row items-center justify-center pr-4 pt-4">
          <ExtensionStandardModalContext.Provider
            value={{
              topic: content,
              setContent: handleExtensionSetContent,
              handleEditPending: handleEditPending,
            }}
          >
            <ExtensionIconGroup type="short" />
          </ExtensionStandardModalContext.Provider>

          <button
            type="button"
            className="ml-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            onClick={handleOnEditorClick}
          >
            <span className="sr-only">Open article editor</span>
            <ArrowUpRightIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>

      <form
        className="relative mx-6 mt-4 box-content pb-20"
        onSubmit={handleOnPublishClick}
      >
        <div className="flex flex-row pb-5">
          <div className="hidden sm:flex">
            <Avatar
              size="l"
              initials={userInitials}
              imageUrl={userProfileImage}
            />
          </div>
          <div className="sm:hidden">
            <Avatar
              size="m"
              initials={userInitials}
              imageUrl={userProfileImage}
            />
          </div>
          <label
            htmlFor="content"
            className="text-m sr-only block font-medium text-black"
          >
            Content
          </label>
          <div className="ml-2 w-full sm:ml-4  sm:mt-1">
            <textarea
              autoComplete="off"
              onChange={handleContentChange}
              name="content"
              id="content"
              autoFocus
              ref={textAreaRef}
              tabIndex={0}
              className="block min-h-[10rem] w-full rounded-md border-0 border-transparent p-2 text-sm focus:ring-0 sm:text-lg"
              placeholder="What's happening?"
              value={content}
            />
          </div>
        </div>

        {remainingCharactersLeft() <= 30 ? (
          <div
            className={`${getEditorControlContainerStyles()} flex w-full rounded-full border p-1 transition`}
          >
            <div
              className={`${getCharactersLeftStyle()} flex h-8 w-8 items-center justify-center rounded-full`}
            >
              {remainingCharactersLeft()}
            </div>
            <button
              onClick={handleOnEditorClick}
              className="flex min-h-[2rem] flex-wrap content-center  items-center justify-center rounded-full border border-transparent bg-gray-300 px-4 py-1 text-sm font-medium text-black shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-600 disabled:opacity-70 sm:text-sm"
            >
              Open in Editor
            </button>
          </div>
        ) : null}

        <div className="mt-4 flex w-full flex-col rounded-md border border-gray-200 px-4 pb-4 pt-3">
          <div className="mb-3 flex w-full justify-between">
            <div className="text-sm font-normal text-black sm:text-base">
              Add to post
            </div>

            <div className="flex flex-row">
              <CameraIcon
                className="mr-3 h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
              <MusicalNoteIcon
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>

          {hasUploadedContent() ? (
            renderUploadedContent()
          ) : (
            <Dropzone
              onDrop={onFileDrop}
              accept={{
                "image/*": SUPPORTED_IMAGE_FORMATS,
                "audio/*": SUPPORTED_AUDIO_FORMATS,
                "video/*": SUPPORTED_VIDEO_FORMATS,
              }}
              maxFiles={1}
              maxSize={USER_VALIDATIONS.maxUploadSize}
              noClick
            >
              {({ getRootProps, getInputProps, open }) => (
                <div
                  className="flex w-full items-center justify-center"
                  {...getRootProps()}
                >
                  <label
                    htmlFor="dropzone-file"
                    className="flex min-h-[4rem] w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300  hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <div className="text-sm text-gray-400 sm:text-base">
                        Drag and drop to upload
                      </div>
                    </div>
                    <input
                      {...getInputProps()}
                      onClick={open}
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                    />
                  </label>
                </div>
              )}
            </Dropzone>
          )}
        </div>

        <div className="absolute bottom-4 flex w-full flex-wrap  justify-center sm:flex sm:flex-row-reverse">
          <PublishButton
            isEditMode={isEditMode()}
            disabled={!hasChanges() || isLoading || !isAuthor()}
            isLoading={isLoading}
          />
        </div>
      </form>
      {editsPending && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full cursor-progress items-center justify-center">
          <div className="absolute left-0 top-0 h-full w-full bg-white"></div>
          <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
            <div className="">
              <Loader />
            </div>

            <button
              type="button"
              onClick={handleCancelExtension}
              className="my-6 flex justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-orange-600 disabled:opacity-70"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default StandardModal;
