import { doc, runTransaction, serverTimestamp } from "@firebase/firestore";

import { db } from "utils/firebase";

const handlePostLike = async (postUid: string, userUid: string) => {
  try {
    await runTransaction(db, async (likeTransaction) => {
      const postRef = doc(db, "posts", postUid);
      const postSnap = await likeTransaction.get(postRef);
      const likeRef = doc(db, "likes", `${postUid}_${userUid}`);
      const likeSnap = await likeTransaction.get(likeRef);

      if (!postSnap.exists()) {
        throw new Error("Post does not exist!");
      }

      let likeChange = 0;

      if (likeSnap.exists()) {
        likeTransaction.delete(likeRef);
        likeChange = -1;
      } else {
        likeTransaction.set(likeRef, {
          userId: userUid,
          postId: postUid,
          createdAt: serverTimestamp(),
        });
        likeChange = 1;
      }

      const newPostLike = (postSnap.data()?.likeCount || 0) + likeChange;

      likeTransaction.update(postRef, {
        likeCount: newPostLike,
      });
    }).then(() => Promise.resolve());
  } catch (err) {
    throw new Error(err);
  }
};

export { handlePostLike };
