import { useState, useEffect } from "react";

import { isUserPremium } from "../../pages/api/stripe";
import { User } from "models/User";

export default function usePremiumStatus(user: User) {
  const [premiumStatus, setPremiumStatus] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      const checkPremiumStatus = async function () {
        setPremiumStatus(await isUserPremium());
      };
      checkPremiumStatus();
    }
  }, [user]);

  return premiumStatus;
}
