import {
  useCallback,
  useState,
  SyntheticEvent,
  RefObject,
  useEffect,
  useMemo,
  createContext,
  useRef,
} from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { USER_VALIDATIONS } from "constants/validationRules";
import { Loader, PublishButton } from "components/common";
import { useLeavePageConfirm } from "utils/customHooks";
import { BASE_TEXT } from "constants/editorTools";
import { Post } from "models/Post";
import { useAuth } from "context/AuthContext";
import ExtensionIconGroup from "components/Extensions/ExtensionIconGroup";

const EditorJs = dynamic(() => import("components/Post/Editor"), {
  ssr: false,
});

export const ExtensionArticleModalContext = createContext({
  topic: "",
  content: "",
  setContent: (content: string) => {},
  handleEditPending: (disable: boolean) => {},
});

interface ExpandedModalProps {
  articleTitleRef?: RefObject<HTMLInputElement>;
  onCloseClick: () => void;
  onPublishClick?: (articleContent: string, articleTitle?: string) => void;
  defaultContent?: string;
  isLoading?: boolean;
  editPostData?: Post;
  editPostDataLoading?: boolean;
}

function ExpandedModal({
  articleTitleRef,
  onCloseClick,
  onPublishClick,
  defaultContent,
  isLoading = false,
  editPostData,
  editPostDataLoading,
}: ExpandedModalProps) {
  const { user } = useAuth();

  const [articleContent, setArticleContent] = useState("");
  const [extensionContent, setExtensionContent] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [articleMaxLengthReached, setArticleMaxLengthReached] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [editsPending, setEditsPending] = useState(false);

  const editsPendingRef = useRef(editsPending);

  const isEditMode = !!editPostData?.authorId;

  useEffect(() => {
    editsPendingRef.current = editsPending;
  }, [editsPending]);

  useEffect(() => {
    if (editPostData?.articleContent)
      setArticleContent(editPostData?.articleContent);
    if (editPostData?.articleTitle) setArticleTitle(editPostData?.articleTitle);
  }, [editPostData?.articleContent, editPostData?.articleTitle]);

  const onArticleChange = useMemo(
    () => (currentArticleContent: string, title?: string) => {
      if (
        currentArticleContent !== null &&
        currentArticleContent.length > USER_VALIDATIONS.articlePostMaxLength
      )
        setArticleMaxLengthReached(true);
      else setArticleMaxLengthReached(false);

      if (currentArticleContent !== null)
        setArticleContent(
          currentArticleContent.substring(
            0,
            USER_VALIDATIONS.articlePostMaxLength
          )
        );

      if (title !== null) setArticleTitle(title);
    },
    []
  );

  const isAuthor = useCallback(
    () =>
      !editPostData?.authorId ||
      (!!editPostData?.authorId && user?.uid === editPostData?.authorId),
    [editPostData?.authorId, user?.uid]
  );

  const hasChanges = useCallback(
    () =>
      (!editPostData?.authorId &&
        !!articleContent &&
        !!JSON.parse(articleContent)?.blocks?.length) ||
      (!!editPostData?.authorId &&
        articleContent !== editPostData?.articleContent),
    [articleContent, editPostData?.articleContent, editPostData?.authorId]
  );

  const handlePublishClick = useMemo(
    () => (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsPublishing(true);
      onPublishClick(articleContent, articleTitle);
    },
    [articleContent, articleTitle, onPublishClick]
  );

  useLeavePageConfirm((!!articleContent || !!articleTitle) && !isPublishing);

  const renderEditorJs = useCallback(() => {
    if (editPostDataLoading === false)
      return (
        <EditorJs
          articleTitleRef={articleTitleRef}
          defaultText={defaultContent}
          defaultValue={
            extensionContent || editPostData?.articleContent || articleContent
          }
          defaultTitle={articleTitle}
          readOnly={editsPendingRef.current}
          onChange={onArticleChange}
        />
      );

    return <></>;
    // articleContent and types cause infinite rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editPostData?.authorId,
    editPostDataLoading,
    extensionContent,
    articleContent,
    articleTitle,
    articleTitleRef,
    defaultContent,
    onArticleChange,
  ]);

  const handleExtensionSetContent = (content: string) => {
    setExtensionContent((prev) => {
      const currentEditsPending = editsPendingRef.current;
      handleEditPending(false);
      return currentEditsPending ? content : prev;
    });
  };

  const handleEditPending = (disable: boolean) => setEditsPending(disable);

  const handleCancelExtension = () => setEditsPending(false);

  return (
    <>
      <div className="absolute top-0 mb-8 mt-4 flex w-full flex-row justify-between">
        <div className=" sm:block">
          <button
            type="button"
            className="ml-6 inline-flex items-center rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            onClick={onCloseClick}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mr-6 flex flex-row items-center justify-center">
          <ExtensionArticleModalContext.Provider
            value={{
              topic: articleTitle,
              content: articleContent,
              setContent: handleExtensionSetContent,
              handleEditPending: handleEditPending,
            }}
          >
            <ExtensionIconGroup type="article" />
          </ExtensionArticleModalContext.Provider>

          <div className="ml-6">
            <PublishButton
              isEditMode={isEditMode}
              disabled={
                !hasChanges() ||
                isLoading ||
                articleMaxLengthReached ||
                !isAuthor()
              }
              isLoading={isLoading}
              onClick={handlePublishClick}
            />
          </div>
        </div>
      </div>
      <form
        className="absolute right-0 top-[90px] box-border h-[calc(100vh-90px)]"
        action="#"
        method="POST"
        onSubmit={handlePublishClick}
      >
        <div className=" box-content flex h-[calc(100vh-90px)] w-screen flex-row overflow-auto">
          <label
            htmlFor="content"
            className="text-m sr-only block font-medium text-black"
          >
            Content
          </label>
          <div className=" h-full w-full px-2">{renderEditorJs()}</div>
        </div>
      </form>
      {editsPending && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full cursor-progress items-center justify-center">
          <div className="absolute left-0 top-0 h-full w-full bg-white"></div>
          <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
            <div className="">
              <Loader />
            </div>

            <button
              type="button"
              onClick={handleCancelExtension}
              className="my-6 flex justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-orange-600 disabled:opacity-70"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ExpandedModal;
