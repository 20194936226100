import { Fragment } from "react";

export const withBreaks = (content: string) =>
  !content
    ? []
    : content.split("\n").flatMap((line, index) => {
        return index > 0
          ? [
              <br key={`br-${index}`} />,
              <Fragment key={index}>{line}</Fragment>,
            ]
          : [<Fragment key={index}>{line}</Fragment>];
      });

export const trimToFirstLine = (content: string) => {
  if (!content) return null;
  const lines = content.split("\n");
  return lines.length <= 1 ? lines[0] : lines[0] + "…";
};

export const getInitials = (content: string) => {
  if (!content) return "";

  const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = Array.from(content.matchAll(initialsRegex)) || [];
  const userInitials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return userInitials;
};

export const validateURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const validateEmail = (email: string) => {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "i"
  );
  return emailRegex.test(email);
};

export const withLink = ({ text }) => {
  if (text.length === 0) return <></>;

  const delimiter =
    /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

  return (
    <>
      {text?.split(delimiter).map((word) => {
        const match = word.match(delimiter);
        if (match) {
          const url = match[0];
          return (
            <a
              className="underline"
              href={url.startsWith("http") ? url : `http://${url}`}
              key={url}
              target="_blank"
              rel="noreferrer"
            >
              {url}
            </a>
          );
        }
        return word;
      })}
    </>
  );
};

export const withBreaksAndLinks = (content: string) =>
  !content
    ? []
    : content.split("\n").flatMap((line, index) => {
        return index > 0
          ? [
              <br key={`br-${index}`} />,
              <Fragment key={index}>{withLink({ text: line })}</Fragment>,
            ]
          : [<Fragment key={index}>{withLink({ text: line })}</Fragment>];
      });
