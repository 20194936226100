import { db } from "utils/firebase";
import {
  collection,
  addDoc,
  doc,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";

import { Comment } from "models/Comment";

const addComment = async ({ details }: { details?: Comment }) => {
  try {
    await runTransaction(db, async (commentTransaction) => {
      const postRef = doc(db, "posts", details?.postId);
      const postSnap = await commentTransaction.get(postRef);

      if (!postSnap.exists()) {
        throw new Error("Post does not exist!");
      }

      const newCommentCount = (postSnap.data()?.commentCount || 0) + 1;

      commentTransaction.update(postRef, {
        commentCount: newCommentCount,
      });

      return await addDoc(collection(db, "comments"), {
        ...details,
        createdAt: serverTimestamp(),
      }).then((docRef) => docRef.id);
    });
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

export { addComment };
