import { Fragment } from "react";
import Link from "next/link";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { db } from "utils/firebase";
import { useAuth } from "context/AuthContext";
import { CollectionGroup } from "components/common";
import { updatePost } from "pages/api/post";

interface DeletePostModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  postId: string;
  collection?: string;
}

const DeletePostModal = ({
  open,
  setOpen,
  postId,
  collection,
}: DeletePostModalProps) => {
  const { user } = useAuth();

  const [userProfile] = useDocument(doc(db, `users`, user?.uid));
  const userProfileData = userProfile?.data();

  const onCloseClick = () => setOpen(false);

  const handleCollectionChange = async (id: string) => {
    await updatePost({ docId: postId, details: { collection: id } });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`flex min-h-full w-screen items-center justify-center p-4 text-center sm:items-center`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`"w-full relative my-8 h-full max-w-3xl transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all`}
              >
                <div className="flex flex-row justify-between">
                  <div className="pl-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                      onClick={onCloseClick}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="relative mx-10 mt-4 box-border pb-8">
                  <CollectionGroup
                    onChange={handleCollectionChange}
                    collections={userProfileData?.collections}
                    defaultCollection={collection}
                  />
                </div>

                <div className="flex w-full items-center justify-center">
                  <Link
                    href="/settings"
                    className="cursor-pointer pb-8 text-gray-900 underline"
                  >
                    Edit collections
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeletePostModal;
