import { Fragment } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Popover, Transition } from "@headlessui/react";
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface MenuPopupProps {
  name?: string;
  options: { name: string; icon?: any; href?: string }[];
  isSelected?: boolean;
}

function MenuPopup({ name, options, isSelected }: MenuPopupProps) {
  const { pathname } = useRouter();
  const beginningOfPath = `/${pathname.split("/")[1]}`;

  const isSubMenuItemSelected = (option: string) => {
    if (option === beginningOfPath) return true;

    return false;
  };

  return (
    <Popover className="relative w-full">
      <Popover.Button
        className={classNames(
          isSelected
            ? "border-gray-600 bg-gray-200 text-gray-800"
            : "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900",
          "group/share flex w-full items-center rounded-md px-3 py-2 text-sm font-medium"
        )}
      >
        <span className="sr-only">More options</span>

        <EllipsisHorizontalCircleIcon
          className={classNames(
            isSelected
              ? "text-gray-800"
              : "text-gray-400 group-hover:text-gray-600",
            "mr-3 h-6 w-6 flex-shrink-0  group-hover/share:text-gray-800"
          )}
        />
        {name}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-[100%] top-0 z-10 mt-1 flex w-screen max-w-fit -translate-x-[calc(100%-10px)] sm:left-1/2 sm:-translate-x-1/2  sm:px-4">
          <div className="flex w-screen flex-col rounded-xl bg-white p-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 sm:w-60">
            {options.map((item, index) =>
              item.name === "Divider" ? (
                <div
                  className="mx-3 my-2 border-t border-gray-200"
                  key={`${item.name}_${index}`}
                />
              ) : (
                <Link
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    isSubMenuItemSelected(item.href)
                      ? "border-gray-600 bg-gray-200 text-gray-800"
                      : "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                    "group  flex items-center rounded-md px-3 py-2 text-sm font-medium"
                  )}
                >
                  <item.icon
                    className={classNames(
                      isSubMenuItemSelected(item.href)
                        ? "text-gray-800"
                        : "text-gray-400 group-hover:text-gray-600",
                      "mr-3 h-6 w-6 flex-shrink-0"
                    )}
                    aria-hidden="true"
                  />

                  {item.name}
                </Link>
              )
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default MenuPopup;
