import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  QueryDocumentSnapshot,
  DocumentData,
  getCountFromServer,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { PuzzlePieceIcon } from "@heroicons/react/24/outline";
import Link from "next/link";

import ExtensionIcon from "./ExtensionIcon";
import { useAuth } from "context/AuthContext";
import { db } from "utils/firebase";

interface ExtensionIconGroupProps {
  type?: "short" | "article";
}

const ExtensionIconGroup = ({ type = "short" }: ExtensionIconGroupProps) => {
  const { user } = useAuth();

  const [extensions, extensionsLoading] = useCollection(
    query(
      collection(db, `users/${user?.uid}/extensions`),
      where("installed", "==", true)
    )
  );

  return (
    <>
      <div className="flex flex-row items-center justify-center">
        {!!extensions?.docs.length && !extensionsLoading && (
          <>
            {extensions.docs.map(
              (extension: QueryDocumentSnapshot<DocumentData>) => (
                <ExtensionIcon
                  key={extension.id}
                  extensionId={extension.id}
                  type={type}
                />
              )
            )}
          </>
        )}
        <Link
          href="/extensions"
          className="ml-2 cursor-pointer rounded-full p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
        >
          <PuzzlePieceIcon className=" h-6 w-6 " />
        </Link>
      </div>
    </>
  );
};

export default ExtensionIconGroup;
