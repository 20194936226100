import Image from "next/image";

interface ImageOrHexProps {
  width?: number;
  height?: number;
  imageSrc?: string;
  imageAlt?: string;
}

const ImageOrHex = ({
  width = 32,
  height = 32,
  imageSrc,
  imageAlt,
}: ImageOrHexProps) => {
  if (!imageSrc) return null;

  if (imageSrc.startsWith("#")) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${width}`}
        height={`${height}`}
      >
        <rect width={`${width}`} height={`${height}`} fill={`${imageSrc}`} />
      </svg>
    );
  }

  return (
    <Image
      src={imageSrc}
      alt={imageAlt || "Cover image"}
      width={width}
      height={height}
      className=" rounded-full"
    />
  );
};

export default ImageOrHex;
