import React, { ReactNode, useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";

import Navbar from "./Navbar";
import BasicToast from "./BasicToast";
import { AddPostModal, PremiumSubscriptionModal } from "components/common";

interface LayoutProps {
  isFullScreen?: boolean;
  hideNav?: boolean;
  children: ReactNode;
}

function Layout({
  isFullScreen = false,
  hideNav = false,
  children,
}: LayoutProps) {
  const router = useRouter();

  const [addPostModalOpen, setPostModalOpen] = useState(false);
  const [premiumSubscriptionModalOpen, setPremiumSubscriptionOpen] =
    useState(false);

  const handlePostModalOpen = useCallback(
    (setOpen: boolean) => {
      if (setOpen) {
        router.push({
          pathname: router.pathname,
          query: { ...router.query, publish: "standard" },
        });
      } else {
        const { publish, editId, ...queryWithoutPublishAndEditId } =
          router.query;

        router.push({
          pathname: router.pathname,
          query: queryWithoutPublishAndEditId,
        });
      }
    },
    [router]
  );

  const handlePremiumSubscriptionModalOpen = useCallback(
    (setOpen: boolean) => {
      if (setOpen) {
        router.push({
          pathname: router.pathname,
          query: { ...router.query, getPremium: "true" },
        });
      } else {
        const { getPremium, ...queryWithoutGetPremium } = router.query;

        router.push({
          pathname: router.pathname,
          query: queryWithoutGetPremium,
        });
      }
    },
    [router]
  );

  useEffect(() => {
    if (
      router?.query?.publish === "standard" ||
      router?.query?.publish === "article"
    )
      setPostModalOpen(true);
    else setPostModalOpen(false);

    if (router?.query?.getPremium === "true") setPremiumSubscriptionOpen(true);
    else setPremiumSubscriptionOpen(false);
  }, [router]);

  return (
    <div className="relative flex min-h-screen flex-col overflow-auto sm:flex-row">
      <main
        className={`w-full ${
          isFullScreen || hideNav ? "mt-0 md:ml-0" : "mt-10 md:ml-60 "
        } md:mt-0`}
      >
        {children}
      </main>
      {!hideNav && <Navbar isFullScreen={isFullScreen} />}
      <BasicToast />

      <AddPostModal open={addPostModalOpen} setOpen={handlePostModalOpen} />
      <PremiumSubscriptionModal
        open={premiumSubscriptionModalOpen}
        setOpen={handlePremiumSubscriptionModalOpen}
      />
    </div>
  );
}

export default Layout;
