import Link from "next/link";

function Unauthenticated({}) {
  return (
    <div className="flex h-full w-full items-center justify-center p-10 text-sm sm:text-base">
      No account found!&nbsp;{" "}
      <Link
        href="/login"
        className="text-orange-500 underline hover:text-orange-600"
      >
        Login
      </Link>{" "}
      &nbsp;or&nbsp;{" "}
      <Link
        href="/register"
        className="text-orange-500 underline hover:text-orange-600"
      >
        Sign up{" "}
      </Link>{" "}
      &nbsp;to create one.
    </div>
  );
}

export default Unauthenticated;
