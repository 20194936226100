import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { updatePinPost } from "pages/api/post";
import DeletePostModal from "./DeletePostModal";
import CollectionGroupModal from "./CollectionGroupModal";
import { ErrorWithCode } from "models/ErrorWithCode";
import { ERROR_CODES } from "constants/errorCodes";
import { USER_VALIDATIONS } from "constants/validationRules";
import usePremiumStatus from "hooks/app/usePremiumStatus";
import { useRouter } from "next/router";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface PostPopupProps {
  postId: string;
  isPinned?: boolean;
  isArticle?: boolean;
  collection?: string;
}

const PostPopup = ({
  postId,
  isPinned = false,
  isArticle = false,
  collection,
}: PostPopupProps) => {
  const { user } = useAuth();
  const router = useRouter();

  const userIsPremium = usePremiumStatus(user);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [collectionGroupModalOpen, setCollectionGroupModalOpen] =
    useState(false);

  const handleDeleteModalSetOpen = (setOpen: boolean) =>
    setDeleteModalOpen(setOpen);

  const handleCollectionGroupModalSetOpen = (setOpen: boolean) =>
    setCollectionGroupModalOpen(setOpen);

  const handleEditModalOpen = () => {
    if (userIsPremium) {
      if (isArticle) {
        router.push({
          pathname: router.pathname,
          query: { ...router.query, publish: "article", editId: postId },
        });
      } else {
        router.push({
          pathname: router.pathname,
          query: { ...router.query, publish: "standard", editId: postId },
        });
      }
    }
  };

  const handleDeleteModalOpen = () => setDeleteModalOpen(true);

  const handleCollectionGroupModalOpen = () =>
    setCollectionGroupModalOpen(true);

  const handlePinPost = async () => {
    await updatePinPost(postId, user?.uid)
      .then(() => toast.success(`${isPinned ? "Unpinned" : "Pinned"} post`))
      .catch((err) => {
        if (err instanceof ErrorWithCode) {
          if (err.code === ERROR_CODES.maximumPinnedPosts) {
            toast.error(
              `You can only pin ${USER_VALIDATIONS.maximumPinnedPosts} posts at a time.`
            );
          } else {
            toast.error(`Error pinning post`);
          }
        } else {
          toast.error(`Error pinning post`);
        }
      });
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full p-1 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5  focus:shadow-lg focus:outline-1 focus:ring-1 focus:ring-black focus:ring-opacity-5">
              {userIsPremium ? (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleEditModalOpen}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handlePinPost}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full whitespace-nowrap px-4 py-2 text-left text-sm"
                        )}
                      >
                        {isPinned ? "Unpin post" : "Pin post"}
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleCollectionGroupModalOpen}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full whitespace-nowrap px-4 py-2 text-left text-sm"
                        )}
                      >
                        Add to collection
                      </button>
                    )}
                  </Menu.Item>
                </>
              ) : null}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleDeleteModalOpen}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    Delete
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {collectionGroupModalOpen && (
        <CollectionGroupModal
          open={collectionGroupModalOpen}
          setOpen={handleCollectionGroupModalSetOpen}
          postId={postId}
          collection={collection}
        />
      )}
      {deleteModalOpen && (
        <DeletePostModal
          open={deleteModalOpen}
          setOpen={handleDeleteModalSetOpen}
          postId={postId}
        />
      )}
    </>
  );
};

export default PostPopup;
