import { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "next/link";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/browser";
import { useSessionStorage } from "react-use";
dayjs.extend(relativeTime);

import { db } from "utils/firebase";
import { useAuth } from "context/AuthContext";
import { handlePostLike } from "pages/api/like";
import { withBreaksAndLinks } from "utils/textUtils";
import { toast } from "react-toastify";
import { Post } from "models/Post";
import { User } from "models/User";
import { Avatar } from "components/common";
import { getInitials } from "utils/textUtils";
import ArticleSummary from "./ArticleSummary";
import AudioPlayer from "./AudioPlayer";
import VideoPlayer from "./VideoPlayer";
import ImageView from "./ImageView";
import PostInteractions from "./PostInteractions";
import useWindowSize from "hooks/window/useWindowSize";
import { mobile } from "config/media";
import {
  MAX_LIKES_PER_SECOND,
  SOFT_LIMIT_LIKES_PER_SECOND,
} from "constants/hardLimits";
import { normalizeDate } from "utils/dateUtils";
import PostPopup from "./PostPopup";
import { BASE_URL } from "constants/redirects";
import { getProfileLink } from "utils/linkUtils";

interface StandardPostProps {
  id: string;
  postData?: Post;
  userData?: User;
  isEmbedded?: boolean;
  isProfileFeed?: boolean;
}

function StandardPost({
  id,
  postData,
  userData,
  isEmbedded = false,
  isProfileFeed = false,
}: StandardPostProps) {
  const { user } = useAuth();
  const router = useRouter();
  const routerQuery = router.query;

  const [backUrlStorage, setBackUrlStorage] = useSessionStorage("backUrl", "");

  // Temp hard limits
  const [firstLike, setFirstLike] = useState(null);
  const [numberOfLikes, setNumberOfLikes] = useState(0);

  const { width } = useWindowSize();
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : BASE_URL;

  const getPathname = () =>
    routerQuery?.profileId ? `/${routerQuery?.profileId}` : `/home`;

  const expandedPostLink = `${isEmbedded ? "/embed" : ""}/posts/${id}`;

  const handleLikeClick = async () => {
    // Temp hard limits
    const now = new Date().getTime();

    if (!firstLike || now - 1000 >= firstLike) {
      setFirstLike(now);
      setNumberOfLikes(1);
    } else {
      if (numberOfLikes >= MAX_LIKES_PER_SECOND) {
        toast.error("`Please try again in 10 seconds.`");
        return false;
      } else {
        setNumberOfLikes((changes) => changes + 1);

        if (numberOfLikes >= SOFT_LIMIT_LIKES_PER_SECOND) {
          Sentry.captureMessage(`Soft limit likes reached - ${user.uid}`);
        }
      }
    }

    user ? await handlePostLike(id, user?.uid || "") : router.push("/login");
  };

  const [likeDoc] = useDocument(doc(db, "likes", `${id}_${user?.uid}`));

  const [postUpdate] = useDocument(doc(db, "posts", id));
  const postUpdateData = postUpdate?.data();

  const handlePostClick = () => {
    setBackUrlStorage(getPathname());
  };

  return (
    <div className="relative flex w-full max-w-3xl break-words" key={id}>
      <div className="flex w-full  flex-col px-4 pb-2 pt-4 hover:bg-gray-50">
        <div>
          <Link
            className="absolute left-0 top-0 h-full w-full cursor-pointer "
            href={expandedPostLink}
            onClick={handlePostClick}
          />
          <div className="relative flex h-full items-start  justify-center">
            <div className="flex h-full flex-col items-start justify-start">
              <Link
                href={getProfileLink(userData)}
                className="rounded-full border border-transparent hover:border-orange-500"
              >
                <Avatar
                  initials={getInitials(userData?.name)}
                  imageUrl={userData?.profileImageUrl}
                  size={width < mobile ? "m" : "l"}
                />
              </Link>
              <Link
                href={expandedPostLink}
                onClick={handlePostClick}
                className="h-full w-full cursor-pointer pr-3"
              ></Link>
            </div>
            <div className="flex h-full min-w-0 flex-1 flex-col items-start justify-start">
              <div className="flew-row flex w-full items-center justify-between">
                <div className="flew-row mb-1 flex w-full items-center justify-start">
                  <Link
                    className="text-md cursor-pointer whitespace-nowrap  pl-3 text-sm font-medium text-gray-900 hover:underline  sm:text-base"
                    href={getProfileLink(userData)}
                  >
                    {userData?.name || "Anonymous"}
                  </Link>

                  <Link
                    href={expandedPostLink}
                    onClick={handlePostClick}
                    className="flex h-full w-full cursor-pointer items-center"
                  >
                    <span className="ml-4 flex items-center justify-start whitespace-nowrap text-sm text-gray-600">
                      {dayjs(normalizeDate(postData?.createdAt)).fromNow()}
                    </span>

                    <div></div>
                  </Link>
                </div>

                <div className="flex flex-row items-center justify-end gap-x-1">
                  {isProfileFeed && !!postUpdateData?.pinned ? (
                    <span className="text-gray-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pin-angle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.92 5.92 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146z" />
                      </svg>
                    </span>
                  ) : null}
                  {user?.uid === postData?.authorId ? (
                    <PostPopup
                      postId={id}
                      isPinned={!!postUpdateData?.pinned}
                      isArticle={!!postUpdateData?.type.includes("article")}
                      collection={postData?.collection}
                    />
                  ) : null}
                </div>
              </div>

              {postData?.shortTextContent ? (
                <p className="mb-0 w-full pb-5 pl-3 pt-2 text-left text-sm font-normal text-black sm:text-base">
                  {withBreaksAndLinks(postData?.shortTextContent)}
                </p>
              ) : null}

              {postData?.articleContent ? (
                <Link
                  href={expandedPostLink}
                  onClick={handlePostClick}
                  className="w-full cursor-pointer pl-3"
                >
                  <div className="py-3">
                    <ArticleSummary
                      articleContent={postData?.articleContent}
                      articleTitle={postData?.articleTitle}
                    />
                  </div>
                </Link>
              ) : null}

              {postData?.audioSrc ? (
                <div className="pl-3 pt-5">
                  <AudioPlayer audioSrc={postData?.audioSrc} />
                </div>
              ) : null}

              {postData?.videoSrc ? (
                <div className="pl-3 pt-5">
                  <VideoPlayer videoSrc={postData?.videoSrc} />
                </div>
              ) : null}

              {postData?.imageSrc ? (
                <div className="pl-3 pt-5">
                  <ImageView imageSrc={postData?.imageSrc} />
                </div>
              ) : null}

              <PostInteractions
                commentLink={expandedPostLink}
                handleExpandPost={handlePostClick}
                numberOfLikes={postUpdateData?.likeCount}
                numberOfComments={postUpdateData?.commentCount}
                onLikeClick={handleLikeClick}
                isLiked={likeDoc?.exists()}
                postUrl={`${origin}/posts/${id}`}
                shareTextContent={
                  postData?.shortTextContent ||
                  postData?.articleTitle ||
                  "Check this out!"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StandardPost;
