const ALLOWED_IFRAMES = [
  "facebook.com",
  "instagram.com",
  "youtube.com",
  "twitter.com",
  "twitch.com",
  "miro.com",
  "vimeo.com",
  "gfycat.com",
  "imgur.com",
  "codepen.io",
  "pinterest.com",
];

export { ALLOWED_IFRAMES };
