import { Fragment } from "react";
import editorJSHTML from "editorjs-html";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";

import { withBreaksAndLinks } from "./textUtils";
import { interweaveTransform } from "utils/htmlUtils";
import Link from "next/link";

const getHTMLContent = (articleContent) => {
  try {
    return editorJSHTML().parse(JSON.parse(articleContent));
  } catch {
    (err) => {
      throw err;
    };
  }
};

const renderArticleContent = (articleContent, isSummary = false) => {
  const htmlContent = getHTMLContent(articleContent);
  if (htmlContent) {
    const resizedHTMLContent = isSummary
      ? htmlContent.slice(0, 10)
      : htmlContent;

    const footnotes = JSON.parse(articleContent)?.blocks?.reduce(
      (acc, block) => {
        const blockFootnotes = block?.tunes?.footnotes;
        if (blockFootnotes && blockFootnotes?.length) {
          return [...acc, ...blockFootnotes];
        }
        return acc;
      },
      []
    );

    return (
      <>
        {resizedHTMLContent.map((block) => {
          if (typeof block !== "string") return null;

          const isCodeBlock = block.startsWith("<pre><code>");
          const hasFootnote = block?.includes("</sups>");

          if (isCodeBlock) {
            const strippedCodeBlock = block
              .replace("<pre><code>", "")
              .replace("</code></pre>", "");
            return (
              <Fragment key={block}>
                <pre>
                  <code>
                    <Interweave
                      noWrap
                      newWindow
                      escapeHtml={true}
                      content={strippedCodeBlock}
                    />
                  </code>
                </pre>
              </Fragment>
            );
          }

          return (
            <Fragment key={block}>
              <Interweave
                noWrap
                content={block}
                newWindow
                transform={interweaveTransform}
                matchers={[new UrlMatcher("url")]}
              />
            </Fragment>
          );
        })}

        {footnotes?.length !== 0 ? (
          <div className="mt-5">
            <div className="w-full border border-b border-gray-200" />
            <ol className="list-inside list-decimal">
              {footnotes.map((footnote, index) => {
                return (
                  <li
                    key={footnote?.id}
                    className="text-sm"
                    id={`footnote-${footnote?.id}`}
                  >
                    <Link
                      href={`#${footnote?.id}`}
                      style={{ textDecoration: "none" }}
                      className="mr-2 hover:text-orange-600"
                    >
                      ^
                    </Link>
                    <span>
                      <Interweave
                        noWrap
                        newWindow
                        content={footnote?.content.toString()}
                        transform={interweaveTransform}
                        matchers={[new UrlMatcher("url")]}
                      />
                    </span>
                  </li>
                );
              })}
            </ol>
          </div>
        ) : null}
      </>
    );
  }

  return null;
};

export { getHTMLContent, renderArticleContent };
