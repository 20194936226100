import { renderArticleContent } from "utils/articleUtils";

interface ArticleSummaryProps {
  articleContent?: string;
  articleTitle?: string;
}

function ArticleSummary({ articleContent, articleTitle }: ArticleSummaryProps) {
  return (
    <div className="relative rounded-md border border-gray-200">
      <div className="absolute h-full w-full rounded-md bg-gradient-to-b from-transparent to-white " />
      <div className=" flex h-[12rem] w-full flex-col overflow-hidden  p-5">
        {articleTitle ? (
          <div className="mb-5 text-lg font-bold sm:text-xl">
            {articleTitle}
          </div>
        ) : null}
        <article className="prose">
          {renderArticleContent(articleContent, true)}
        </article>
      </div>
    </div>
  );
}

export default ArticleSummary;
