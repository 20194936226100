import { db } from "utils/firebase";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  getDocs,
  deleteField,
  query,
  where,
  runTransaction,
  writeBatch,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

import { Post } from "models/Post";
import { USER_VALIDATIONS } from "constants/validationRules";
import { ErrorWithCode } from "models/ErrorWithCode";
import { ERROR_CODES } from "constants/errorCodes";
import { BASE_URL } from "constants/redirects";

const addPost = async ({ details }: { details?: Post }) => {
  try {
    const newPost = {
      ...details,
      commentCount: 0,
      likeCount: 0,
      isArchived: false,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    const newPostRef = doc(collection(db, "posts"));

    // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1043389401
    return await setDoc(newPostRef, newPost).then(async () => {
      return newPostRef.id;
    });
  } catch (err) {
    console.log("Error adding post: ", err);
    throw new Error("Not allowed ", err);
  }
};

const updatePost = async ({
  docId,
  details,
  isNewVersion,
}: {
  docId?: string;
  details?: Post;
  isNewVersion?: boolean;
}) => {
  try {
    const newPost = {
      ...details,
      updatedAt: serverTimestamp(),
    };

    if (docId && docId !== "0") {
      const postRef = doc(db, "posts", docId || "0");
      const docSnap = await getDoc(postRef);
      // Update old post to version if new post is a new version, then update post
      const batch = writeBatch(db);

      if (isNewVersion) {
        const versionRef = doc(collection(db, `posts/${docId}/versions`));
        batch.set(versionRef, docSnap.data());
      }

      batch.update(postRef, newPost);

      await batch.commit();

      return docId;
    } else {
      return await addPost({ details }).then(async (docId) => {
        return docId;
      });
    }

    Promise.resolve();
  } catch (err) {
    console.log("err", err);
    throw new Error("Not allowed", err);
  }
};

const updatePinPost = async (docId: string, userId: string) => {
  try {
    const pinnedPosts = await getDocs(
      query(
        collection(db, "posts"),
        where("authorId", "==", userId),
        where("pinned", "in", [1, 2, 3, 4, 5, 6])
      )
    );
    const postRef = doc(db, "posts", docId);
    const postDocSnap = await getDoc(postRef);

    if (pinnedPosts.empty) {
      if (postDocSnap.exists()) {
        await updateDoc(postRef, { pinned: 1 });
      }
    } else {
      const pinnedBatch = writeBatch(db);
      const isPostPinned = !!postDocSnap?.data()?.pinned;

      if (
        !isPostPinned &&
        pinnedPosts.size >= USER_VALIDATIONS.maximumPinnedPosts
      ) {
        throw new ErrorWithCode(ERROR_CODES.maximumPinnedPosts);
      }

      if (!isPostPinned) {
        pinnedBatch.update(postRef, { pinned: pinnedPosts.size + 1 });
      } else {
        // If doc is pinned, unpin it and shuffle the rest
        pinnedPosts.docs.forEach((docSnap) => {
          const docData = docSnap.data();

          if (docSnap?.id === docId) {
            pinnedBatch.update(docSnap.ref, {
              pinned: isPostPinned ? deleteField() : pinnedPosts.size + 1,
            });
          } else if (isPostPinned) {
            if (docData.pinned > isPostPinned) {
              pinnedBatch.update(docSnap.ref, { pinned: docData.pinned - 1 });
            }
          }
        });
      }

      await pinnedBatch.commit();
    }

    Promise.resolve();
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

const archivePost = async ({
  docId,
  userId,
}: {
  docId: string;
  userId: string;
}) => {
  try {
    const postRef = doc(db, "posts", docId);
    const docSnap = await getDoc(postRef);

    if (docSnap.exists()) {
      const subscribers = await getDocs(
        query(
          collection(db, "subscriptions"),
          where("subscriptionId", "==", docSnap.data().authorId)
        )
      );

      // Unpin post if pinned
      if (!!docSnap?.data()?.pinned) {
        await updatePinPost(docId, userId);
      }

      await updateDoc(postRef, { isArchived: true });
      await updateDoc(
        doc(db, `publicUsers/${docSnap.data().authorId}/feed`, docId),
        { isArchived: true }
      );

      if (subscribers.docs.length > 0) {
        const subscriptionUpdateBatch = writeBatch(db);

        subscribers.forEach((subscriber) => {
          if (subscriber?.data()?.subscriberId) {
            const subscriptionRef = doc(
              db,
              `publicUsers/${subscriber?.data()?.subscriberId}/feed`,
              docId
            );
            subscriptionUpdateBatch.set(subscriptionRef, { isArchived: true });
          }
        });

        await subscriptionUpdateBatch.commit();
      }
    }

    Promise.resolve();
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

export { addPost, updatePost, updatePinPost, archivePost };
