const mobile = 640;
const tablet = 768;
const laptop = 1024;
const laptopL = 1440;
const desktop = 2560;

const device = {
  mobile: `(max-width: ${mobile}px)`,
  tablet: `(max-width: ${tablet}px)`,
  laptop: `(max-width: ${laptop}px)`,
  laptopL: `(max-width: ${laptopL}px)`,
  desktop: `(max-width: ${desktop}px)`,
  desktopL: `(max-width: ${desktop}px)`,
};
export { device, mobile, tablet, laptop, laptopL, desktop };
