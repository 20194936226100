import { useState } from "react";

import { ImageOrHex } from "components/common";
import { User } from "models/User";

interface CollectionGroupProps {
  collections: User["collections"];
  onChange: (collectionId: string) => void;
  defaultCollection?: string;
}

const CollectionGroup = ({
  collections = [],
  onChange,
  defaultCollection,
}: CollectionGroupProps) => {
  const [selectedCollectionId, setSelectedCollectionId] =
    useState(defaultCollection);

  const handleSelection = (id: string) => {
    setSelectedCollectionId(id);
    onChange && onChange(id);
  };

  const renderSelectedItem = () => {
    const selectedCollection = collections.find(
      (collection) => collection.id === selectedCollectionId
    );

    if (!selectedCollection) return null;

    return (
      <button
        type="button"
        className="group flex flex-col items-center justify-center"
        onClick={() => handleSelection("")}
      >
        <div className="mb-2 h-[60px] w-[60px] overflow-hidden rounded-full border-2 border-orange-600">
          <ImageOrHex
            imageSrc={selectedCollection?.coverImageSrc}
            imageAlt={selectedCollection?.title}
            width={100}
            height={100}
          />
        </div>
        <div className="underline">
          {selectedCollection?.title || "Collection"}
        </div>
      </button>
    );
  };

  return (
    <div className="flex items-center">
      <label htmlFor="collections" className="sr-only mr-2">
        Collections
      </label>
      <div
        className="flex flex-row items-center justify-start gap-x-10 overflow-x-auto px-4 pb-6 pr-5 sm:px-6 lg:px-8"
        id="collections"
      >
        {renderSelectedItem()}

        {collections?.map((collection) => {
          if (collection.id === selectedCollectionId) return null;

          return (
            <button
              key={collection.id}
              type="button"
              className="group flex flex-col items-center justify-center"
              onClick={() => handleSelection(collection.id)}
            >
              <div className="mb-2 h-[60px] w-[60px] overflow-hidden rounded-full border-2 border-transparent group-hover:border-orange-600">
                <ImageOrHex
                  imageSrc={collection?.coverImageSrc}
                  imageAlt={collection?.title}
                  width={100}
                  height={100}
                />
              </div>
              <div className=" group-hover:underline">
                {collection?.title || "Collection"}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default CollectionGroup;
