const SOFT_LIMIT_LIKES_PER_SECOND = 5;
const SOFT_LIMIT_SUBSCRIPTIONS_PER_SECOND = 5;
const SOFT_LIMIT_COMMENTS_PER_SECOND = 5;
const SOFT_LIMIT_POSTS_PER_SECOND = 5;
const SOFT_LIMIT_EDIT_ACCOUNT_PER_SECOND = 5;

const MAX_LIKES_PER_SECOND = 10;
const MAX_SUBSCRIPTIONS_PER_SECOND = 10;
const MAX_COMMENTS_PER_SECOND = 10;
const MAX_POSTS_PER_SECOND = 10;
const MAX_EDIT_ACCOUNT_PER_SECOND = 10;

export {
  SOFT_LIMIT_LIKES_PER_SECOND,
  SOFT_LIMIT_SUBSCRIPTIONS_PER_SECOND,
  SOFT_LIMIT_COMMENTS_PER_SECOND,
  SOFT_LIMIT_POSTS_PER_SECOND,
  SOFT_LIMIT_EDIT_ACCOUNT_PER_SECOND,
  MAX_LIKES_PER_SECOND,
  MAX_SUBSCRIPTIONS_PER_SECOND,
  MAX_COMMENTS_PER_SECOND,
  MAX_POSTS_PER_SECOND,
  MAX_EDIT_ACCOUNT_PER_SECOND,
};
