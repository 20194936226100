import { useState, useEffect, useCallback, Fragment } from "react";
import { useRouter } from "next/router";
import { doc, collection, query, where } from "firebase/firestore";
import Image from "next/image";
import Link from "next/link";
import { Disclosure, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useSessionStorage } from "react-use";

import { useAuth } from "context/AuthContext";
import { NAVIGATION_OPTIONS } from "./constants";
import { Avatar } from "components/common";
import { getInitials } from "utils/textUtils";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { db } from "utils/firebase";
import usePremiumStatus from "hooks/app/usePremiumStatus";
import MenuPopup from "./MenuPopup";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export async function getServerSideProps(context) {
  const { req } = context;
  const host = req?.headers?.host || "";

  return {
    props: {
      host: host || "0",
    },
  };
}

interface NavbarProps {
  host?: string;
  isFullScreen?: boolean;
}

function Navbar({ host, isFullScreen = false }: NavbarProps) {
  const { user } = useAuth();
  const router = useRouter();
  const userIsPremium = usePremiumStatus(user);
  const routerPath = router.pathname;
  const beginningOfPath = `/${routerPath.split("/")[1]}`;

  const [backUrlStorage, setBackUrlStorage] = useSessionStorage("backUrl", "");

  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  const [userData] = useDocument(doc(db, `users`, user?.uid || "0"));
  const [userProfileCollection] = useCollection(
    query(
      collection(db, `publicUsers`),
      where("customDomainUrl", "==", origin || "0")
    )
  );
  const userProfile = userProfileCollection?.docs[0];
  const profileId = userProfile?.data()?.username;

  const isOwner =
    userData?.data()?.username && userData?.data()?.username === profileId;

  const isSelectedOption = (option: string) => {
    if (option === beginningOfPath) {
      if (option === "/login") return false;

      return true;
    } else if (
      option === "/login" &&
      !NAVIGATION_OPTIONS.find((item) => item.href === beginningOfPath) &&
      userData?.data()?.username === profileId
    ) {
      return true;
    } else if (beginningOfPath === "/posts") {
      if (option === "/") return true;
    } else if (
      option === "/explore" &&
      beginningOfPath !== "/posts" &&
      !NAVIGATION_OPTIONS.find((item) => item.href === beginningOfPath) &&
      userData?.data()?.username !== profileId &&
      beginningOfPath !== "/subscriptions" &&
      beginningOfPath !== "/subscribers" &&
      beginningOfPath !== "/plugins" &&
      beginningOfPath !== "/settings"
    ) {
      return true;
    } else if (
      option === "more" &&
      (beginningOfPath === "/subscriptions" ||
        beginningOfPath === "/subscribers" ||
        beginningOfPath === "/plugins" ||
        beginningOfPath === "/settings")
    ) {
      return true;
    }
    return false;
  };

  const handleAddPostClick = () => {
    if (userIsPremium) {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, publish: "standard" },
      });
    } else {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, getPremium: "true" },
      });
    }
  };

  const renderFullSizeNavigationOptions = () => (
    <>
      {isFullScreen ? (
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel
            className={`${
              isFullScreen ? "mt-14" : "mt-0"
            } hidden min-h-screen w-60 space-y-2 p-2 pb-4 md:flex md:flex-col`}
          >
            {NAVIGATION_OPTIONS.map((item) =>
              item?.options ? (
                <Fragment key={item.name}>
                  <MenuPopup
                    name={item.name}
                    isSelected={isSelectedOption("more")}
                    options={item.options}
                  />
                </Fragment>
              ) : (
                <Link
                  key={item.name}
                  href={
                    item.name === "Profile" && userData?.data()?.username
                      ? `${userData?.data()?.username}` || item.href
                      : item.href
                  }
                  className={classNames(
                    isSelectedOption(item.href)
                      ? "border-gray-600 bg-gray-200 text-gray-800"
                      : "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                    "group  flex items-center rounded-md px-3 py-2 text-sm font-medium"
                  )}
                >
                  {item.name === "Profile" ? (
                    <span className="mr-3 h-6 w-6 flex-shrink-0">
                      <Avatar
                        size="s"
                        initials={getInitials(userData?.data()?.name)}
                        imageUrl={userData?.data()?.profileImageUrl}
                      />
                    </span>
                  ) : (
                    <item.icon
                      className={classNames(
                        isSelectedOption(item.href)
                          ? "text-gray-800"
                          : "text-gray-400 group-hover:text-gray-600",
                        "mr-3 h-6 w-6 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  )}
                  {item.name}
                </Link>
              )
            )}
            {isOwner && (
              <div className="mt-6 hidden w-full flex-wrap content-center justify-end px-4 pt-4 md:flex md:justify-center">
                <button
                  onClick={handleAddPostClick}
                  type="button"
                  className="flex w-full flex-wrap content-center items-center justify-center rounded-full border border-transparent bg-orange-600  py-2 px-7 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                  <Image
                    className="mr-2 h-4 w-auto"
                    src="/images/starlo_stars_white.svg"
                    alt="Starlo Stars"
                    width={20}
                    height={20}
                    priority
                  />
                  Publish
                </button>
              </div>
            )}
          </Disclosure.Panel>
        </Transition>
      ) : (
        <div
          className={`${
            isFullScreen ? "mt-12" : "mt-0"
          } hidden min-h-screen w-60 space-y-2 p-2 pb-4 md:flex md:flex-col`}
        >
          {NAVIGATION_OPTIONS.map((item) =>
            item?.options ? (
              <Fragment key={item.name}>
                <MenuPopup
                  key={item.name}
                  name={item.name}
                  isSelected={isSelectedOption("more")}
                  options={item.options}
                />
              </Fragment>
            ) : (
              <Link
                key={item.name}
                href={
                  item.name === "Profile" && userData?.data()?.username
                    ? `/${userData?.data()?.username}` || item.href
                    : item.href
                }
                className={classNames(
                  isSelectedOption(item.href)
                    ? "border-gray-600 bg-gray-200 text-gray-800"
                    : "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                  "group  flex items-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-orange-500"
                )}
              >
                {item.name === "Profile" ? (
                  <span className="mr-3 h-6 w-6 flex-shrink-0">
                    <Avatar
                      size="s"
                      initials={getInitials(userData?.data()?.name)}
                      imageUrl={userData?.data()?.profileImageUrl}
                    />
                  </span>
                ) : (
                  <item.icon
                    className={classNames(
                      isSelectedOption(item.href)
                        ? "text-gray-800"
                        : "text-gray-400 group-hover:text-gray-600",
                      "mr-3 h-6 w-6 flex-shrink-0"
                    )}
                    aria-hidden="true"
                  />
                )}
                {item.name}
              </Link>
            )
          )}
          {isOwner && (
            <div className="mt-6 hidden w-full flex-wrap content-center justify-end px-4 pt-4 md:flex md:justify-center">
              <button
                onClick={handleAddPostClick}
                type="button"
                className="flex w-full flex-wrap content-center items-center justify-center rounded-full border border-transparent bg-orange-600  py-2 px-7 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              >
                <Image
                  className="mr-2 h-4 w-auto"
                  src="/images/starlo_stars_white.svg"
                  alt="Starlo Stars"
                  width={20}
                  height={20}
                  priority
                />
                Publish
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );

  const renderMenuBar = (open: boolean) => {
    if (isFullScreen) {
      if (profileId && isFullScreen && userProfile?.data()?.backButtonText) {
        const backButtonText = userProfile?.data().backButtonText;

        if (userProfile?.data()?.backButtonUrl) {
          return (
            <div
              className={`absolute left-3 top-3 flex min-w-fit flex-row items-start  justify-start md:flex`}
            >
              <Disclosure.Button
                onClick={(e) => e.currentTarget.blur()}
                className={`group inline-flex items-center justify-center ${
                  open ? "rounded-md focus:ring-inset" : "rounded-full"
                } relative mr-3 w-8  hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500`}
              >
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XMarkIcon
                    className="block h-8 w-8 rounded-md p-1 text-gray-400 group-hover:bg-gray-100"
                    aria-hidden="true"
                  />
                ) : (
                  <Image
                    className="block h-8 w-8 rounded-full bg-white p-0.5 hover:bg-gray-100"
                    src="/app-icon.png"
                    alt="Starlo stars"
                    width={50}
                    height={50}
                    aria-hidden="true"
                    priority
                  />
                )}
              </Disclosure.Button>
              {open ? null : (
                <Link
                  href={userProfile?.data().backButtonUrl}
                  className=" flex h-8 items-center justify-center  whitespace-nowrap rounded-full bg-white px-4  text-sm shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                  {backButtonText}
                </Link>
              )}
            </div>
          );
        }

        return (
          <div
            className={`absolute left-3 top-3 flex min-w-fit items-start justify-start  md:flex`}
          >
            <Disclosure.Button
              onClick={(e) => e.currentTarget.blur()}
              className={`group inline-flex items-center justify-center ${
                open ? "rounded-md focus:ring-inset" : "rounded-full"
              } hover:text-gray-500 focus:outline-none focus:ring-2  focus:ring-orange-500`}
            >
              <span className="sr-only">{backButtonText}</span>
              {open ? (
                <XMarkIcon
                  className="block h-8 w-8 rounded-md p-1 text-gray-400 group-hover:bg-gray-100"
                  aria-hidden="true"
                />
              ) : (
                <div className="flex h-8 items-center justify-center whitespace-nowrap rounded-full bg-white px-4 text-sm shadow-md hover:bg-gray-100">
                  {backButtonText}
                </div>
              )}
            </Disclosure.Button>
          </div>
        );
      }

      return (
        <div
          className={`absolute left-3 top-3 flex w-8 items-start justify-start  md:flex`}
        >
          <Disclosure.Button
            onClick={(e) => e.currentTarget.blur()}
            className={`group inline-flex items-center justify-center ${
              open ? "rounded-md focus:ring-inset" : "rounded-full"
            } hover:text-gray-500 focus:outline-none focus:ring-2  focus:ring-orange-500`}
          >
            <span className="sr-only">Open main menu</span>
            {open ? (
              <XMarkIcon
                className="block h-8 w-8 rounded-md p-1 text-gray-400 group-hover:bg-gray-100"
                aria-hidden="true"
              />
            ) : (
              <Image
                className="block h-8 w-8 rounded-full bg-white p-0.5 hover:bg-gray-100"
                src="/app-icon.png"
                alt="Starlo stars"
                width={50}
                height={50}
                aria-hidden="true"
                priority
              />
            )}
          </Disclosure.Button>
        </div>
      );
    }

    return (
      <div className="absolute left-0 top-0 flex w-screen flex-row items-center justify-between bg-white p-1 md:hidden md:pt-5">
        <div
          className={`flex items-center justify-end md:py-2 ${
            isFullScreen ? "md:flex" : "md:hidden"
          }`}
        >
          {/* Mobile menu button */}
          <Disclosure.Button
            onClick={(e) => e.currentTarget.blur()}
            className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
          >
            <span className="sr-only">Open main menu</span>
            {open ? (
              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
            ) : (
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            )}
          </Disclosure.Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {isOwner && (
        <div className="fixed bottom-5 right-5 mt-0 flex flex-wrap content-center justify-end md:mt-4 md:hidden">
          <button
            onClick={handleAddPostClick}
            type="button"
            className="text-md flex flex-wrap content-center items-center justify-center rounded-full border border-transparent  bg-orange-600 py-3 px-4 font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 md:px-7"
          >
            <Image
              className="mr-2 h-4 w-auto"
              src="/images/starlo_stars_white.svg"
              alt="Starlo Stars"
              width={20}
              height={20}
              priority
            />
            Publish
          </button>
        </div>
      )}

      <Disclosure
        as="nav"
        className="absolute left-0 top-0 "
        aria-label="Sidebar"
      >
        {({ open }) => (
          <>
            <div className="fixed  flex w-full flex-grow flex-col items-center justify-between border-r  border-gray-200 bg-white shadow-md md:max-w-[15rem] md:flex-row md:items-start md:px-0 md:shadow-none">
              {renderFullSizeNavigationOptions()}
              {isFullScreen ? (
                <Disclosure.Panel className="hidden h-screen w-screen md:flex">
                  <Disclosure.Button className=" h-full w-screen"></Disclosure.Button>
                </Disclosure.Panel>
              ) : null}

              <Disclosure.Panel
                className={`${
                  isFullScreen ? "mt-12" : "mt-12"
                } h-screen w-full md:hidden`}
              >
                <div className="flex flex-col space-y-1 p-2">
                  {NAVIGATION_OPTIONS.map((item) =>
                    item?.options ? (
                      <Fragment key={item.name}>
                        <MenuPopup
                          key={item.name}
                          name={item.name}
                          isSelected={isSelectedOption("more")}
                          options={item.options}
                        />
                      </Fragment>
                    ) : (
                      <Link
                        key={item.name}
                        href={
                          item.name === "Profile" && userData?.data()?.username
                            ? `/${userData?.data()?.username}` || item.href
                            : item.href
                        }
                        className={classNames(
                          isSelectedOption(item.href)
                            ? "border-gray-600 bg-gray-200 text-gray-800"
                            : "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group  flex items-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-orange-500"
                        )}
                      >
                        {item.name === "Profile" ? (
                          <span className="mr-3 h-6 w-6 flex-shrink-0">
                            <Avatar
                              size="s"
                              initials={getInitials(userData?.data()?.name)}
                              imageUrl={userData?.data()?.profileImageUrl}
                            />
                          </span>
                        ) : (
                          <item.icon
                            className={classNames(
                              isSelectedOption(item.href)
                                ? "text-gray-800"
                                : "text-gray-400 group-hover:text-gray-600",
                              "mr-3 h-6 w-6 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                        )}
                        {item.name}
                      </Link>
                    )
                  )}
                </div>
              </Disclosure.Panel>
            </div>

            {renderMenuBar(open)}
          </>
        )}
      </Disclosure>
    </>
  );
}

export default Navbar;
