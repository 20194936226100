import { doc, runTransaction, serverTimestamp } from "@firebase/firestore";

import { db } from "utils/firebase";

const handleExtensionInstall = async (extensionId: string, userUid: string) => {
  try {
    await runTransaction(db, async (extensionTransaction) => {
      const userExtensionRef = doc(
        db,
        `users/${userUid}/extensions`,
        extensionId
      );
      const userExtensionSnap = await extensionTransaction.get(
        userExtensionRef
      );

      const extensionRef = doc(db, `extensions/${extensionId}/users`, userUid);

      const extensionSnap = await extensionTransaction.get(extensionRef);

      if (extensionSnap.exists()) {
        extensionTransaction.update(extensionRef, {
          installed: extensionSnap.data()?.installed ? false : true,
          updatedAt: serverTimestamp(),
        });
      } else {
        extensionTransaction.set(extensionRef, {
          installed: true,
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
        });
      }

      if (userExtensionSnap.exists()) {
        extensionTransaction.update(userExtensionRef, {
          installed: userExtensionSnap.data()?.installed ? false : true,
          updatedAt: serverTimestamp(),
        });
      } else {
        extensionTransaction.set(userExtensionRef, {
          installed: true,
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
        });
      }
    }).then(() => Promise.resolve());
  } catch (err) {
    throw new Error(err);
  }
};

const handleExtensionActivate = async (
  extensionId: string,
  userUid: string
) => {
  try {
    await runTransaction(db, async (extensionTransaction) => {
      const userExtensionRef = doc(
        db,
        `users/${userUid}/extensions`,
        extensionId
      );
      const userExtensionSnap = await extensionTransaction.get(
        userExtensionRef
      );

      const extensionRef = doc(db, `extensions/${extensionId}/users`, userUid);

      const extensionSnap = await extensionTransaction.get(extensionRef);

      if (extensionSnap.exists()) {
        extensionTransaction.update(extensionRef, {
          active: extensionSnap.data()?.active ? false : true,
          updatedAt: serverTimestamp(),
        });
      } else {
        extensionTransaction.set(extensionRef, {
          active: true,
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
        });
      }

      if (userExtensionSnap.exists()) {
        extensionTransaction.update(userExtensionRef, {
          active: userExtensionSnap.data()?.active ? false : true,
          updatedAt: serverTimestamp(),
        });
      } else {
        extensionTransaction.set(userExtensionRef, {
          active: true,
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
        });
      }
    }).then(() => Promise.resolve());
  } catch (err) {
    throw new Error(err);
  }
};

export { handleExtensionInstall, handleExtensionActivate };
