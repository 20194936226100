import { Fragment } from "react";
import Image from "next/image";
import { Dialog, Transition } from "@headlessui/react";

import { useAuth } from "context/AuthContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { createCheckoutSession } from "utils/stripe/createCheckoutSession";

interface PremiumSubscriptionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function PremiumSubscriptionModal({
  open,
  setOpen,
}: PremiumSubscriptionModalProps) {
  const { user } = useAuth();

  const handlePublishAnythingClick = () => createCheckoutSession(user?.uid);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`flex min-h-full w-screen items-center justify-center p-4 text-center sm:items-center`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative my-8 flex h-full w-full max-w-3xl transform flex-col items-center justify-center overflow-hidden rounded-lg bg-white py-16 px-4 text-center shadow-xl transition-all sm:px-8`}
              >
                <button
                  type="button"
                  className="absolute left-2 top-2 inline-flex items-center rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <h3 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl md:text-5xl">
                  Ready to publish?
                </h3>
                <h4 className="mt-8 text-sm font-normal leading-8 text-gray-800 sm:text-lg md:text-xl">
                  <span className="block">
                    Independent publishing for any content type.
                  </span>
                  <span className="block sm:inline-block">
                    Freedom from the algorithms.{" "}
                  </span>
                  <span className="block sm:ml-2 sm:inline-block">
                    {" "}
                    Subscribe to Starlo.
                  </span>
                  <span className="block">
                    Free for 10 days, then $23 a month.
                  </span>
                </h4>
                <button
                  onClick={handlePublishAnythingClick}
                  type="button"
                  tabIndex={0}
                  className="mt-8 flex w-auto flex-wrap content-center items-center justify-center rounded-full border border-transparent bg-orange-600 py-2  px-10 text-sm font-medium text-white shadow-sm transition-all hover:-translate-y-1  hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-base"
                >
                  <Image
                    className="mr-2 h-4 w-auto"
                    src="/images/starlo_stars_white.svg"
                    alt="Starlo Stars"
                    width={20}
                    height={20}
                    priority
                  />
                  Publish Anything
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PremiumSubscriptionModal;
